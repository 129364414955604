import React from "react";
import "../css/BoxContent.css";

export function CriminalLaw() {
	return (
		<React.Fragment>
			<p className="box-content-title">Criminal Law</p>
			<div className="box-content" id="areas-of-law">
				<p>
					Our wealth of experience in all aspects of criminal law will
					provide you with the best possible legal representation.
				</p>
				<p>
					Sam Abbas, the principal solicitor, has extensive experience
					in all areas of criminal law as well as the local knowledge
					of the courts, magistrates and police prosecutors.
				</p>
				<p>
					The expertise enables him to analyse and select the most
					effective strategy for your representation in order to
					obtain the best possible result.
				</p>
				<p>
					Feel free to contact us to arrange a conference at no charge
					or obligation.
				</p>
			</div>
		</React.Fragment>
	);
}

export function TrafficLaw() {
	return (
		<React.Fragment>
			<p className="box-content-title">Traffic Law</p>
			<div className="box-content" id="areas-of-law">
				<p>
					The introduction of increasingly sophisticated techniques being used to penalise drivers for
					traffic violations has resulted in an increase in demerit points, fines and loss
					of driver licences.
				</p>
				<p>
					Our solicitors are highly experienced in the areas of
					licence suspensions, speeding fines, driving whilst
					disqualified, habitual offender listings, drink driving
					charges and their respective court appeals.
				</p>
				<p>
					If you have received a fine, we are free to discuss with you
					all aspects of the infringement and the effect it will have
					on your driver's licence.
				</p>
				<p>Call now for a conference at no charge or obligation.</p>
			</div>
		</React.Fragment>
	);
}

export function Litigation() {
	return (
		<React.Fragment>
			<p className="box-content-title">Civil and Commercal Litigation</p>
			<div className="box-content" id="areas-of-law">
				<p>Going to court can be daunting.</p>
				<p>
					If you are required to engage in court proceedings, 
					Allied Lawyers is available to provide you with the guidance and support
					you need.
				</p>
				<p>
					We will assist you in navigating the complex and confusing
					court system and provide you with comprehensive advice and discuss 
					the legal options available. 
				</p>
				<p>Contact us now to discuss your matter.</p>
			</div>
		</React.Fragment>
	);
}

export function Conveyancing() {
	return (
		<React.Fragment>
			<p className="box-content-title">Sale and Purchase of Property</p>
			<div className="box-content" id="areas-of-law">
				<p>
					Are you looking to purchase or sell a property? Our friendly
					staff is available to assist you with all of your property
					enquiries.
				</p>
				<p>
					We provide you with comprehensive guidance and assistance 
					to help you navigate the complex process of buying or selling
					your property - helping you every step of the way.
				</p>
				<p>
					We are happy to assist with your finance and dealings with
					the bank, as well as negotiations with the real estate
					agents.
				</p>
				<p>
					Our efficient and friendly service is available upon request. 
				</p>
			</div>
		</React.Fragment>
	);
}
