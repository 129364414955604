import React from "react";

export default function Blurb() {
	return (
		<React.Fragment>
			<p style={{ margin: "0" }}>
				At Allied Lawyers, we pride ourselves on our experience and
				skill coupled with friendly service and a personal touch.
			</p>
			<p>
				Our team has extensive experience spanning in excess of 20
				years.
			</p>
			<p>
				We understand the sensitive nature of legal matters and the
				impact they have on your personal and professional life. We
				ensure we provide our clients with clear and comprehensive legal
				advice while being understanding and sympathetic to their
				individual situation.
			</p>
			<p>
				Our highly competent and knowledgeable team of solicitors have
				vast experience and expertise in criminal and traffic law, civil
				litigation, property matters and all aspects of business
				dealings.
			</p>
			<p>All staff are fluent in Arabic for ease of communication.</p>
			<br></br>
			<p>
				<b>First Consultation Free</b>
			</p>
		</React.Fragment>
	);
}
