import {useEffect} from 'react'

import { createMuiTheme, useMediaQuery } from '@material-ui/core';

import $ from 'jquery'

export default function Responsiveness() {
	const theme = createMuiTheme()
	const xs = useMediaQuery(theme.breakpoints.only('xs'))
	const sm = useMediaQuery(theme.breakpoints.only('sm'))
	const md = useMediaQuery(theme.breakpoints.only('md'))
	const lg = useMediaQuery(theme.breakpoints.only('lg'))
	const xl = useMediaQuery(theme.breakpoints.only('xl'))

	//fonts and padding (sometimes)
	useEffect(() => {
		if (xs) {
			$(".content-image").css({"font-size":"25pt"})
			$(".motto").css({"font-size":"12pt"})
			$(".principal").css({"font-size":"9pt"})
			$(".segment-children#about").css({
				"font-size":"9pt",
				"padding":"5% 5%"
			})
		} else if (sm) {
			$(".content-image").css({"font-size":"35pt"})
			$(".motto").css({"font-size":"18pt"})
			$(".principal").css({"font-size":"12pt"})
			$(".segment-children#about").css({
				"font-size":"11pt",
				"padding":"5% 7%"
			})
		} else if (md) {
			$(".content-image").css({"font-size":"50pt"})
			$(".motto").css({"font-size":"25pt"})
			$(".principal").css({"font-size":"15pt"})
			$(".segment-children#about").css({
				"font-size":"12pt",
				"padding":"5% 10%"
			})
		} else if (lg) {
			$(".content-image").css({"font-size":"60pt"})
			$(".motto").css({"font-size":"30pt"})
			$(".principal").css({"font-size":"20pt"})
			$(".segment-children#about").css({
				"font-size":"12pt",
				"padding":"5% 10%"
			})
		} else {
			$(".content-image").css({"font-size":"75pt"})
			$(".motto").css({"font-size":"35pt"})
			$(".principal").css({"font-size":"25pt"})
			$(".segment-children#about").css({
				"font-size":"15pt",
				"padding":"5% 15%"
			})
		}
	}, [xs, sm, md, lg, xl])

	//images
	useEffect(() => {
		if (xs) {
			$("#main-logo").css({
				"width":"150px"
			})
		} else if (sm) {
			$("#main-logo").css({
				"width":"225px"
			})
		} else if (md) {
			$("#main-logo").css({
				"width":"350px"
			})
		} else if (lg) {
			$("#main-logo").css({
				"width":"400px"
			})
		} else {
			$("#main-logo").css({
				"width":"500px"
			})
		}
	}, [xs, sm, md, lg, xl])
}