import React, { useEffect } from "react";

import "./css/App.css";

import $ from "jquery";

import Segment from "./components/Segment";
import Navbar from "./components/Navbar";
import Parallax from "./components/Parallax";
import Blurb from "./components/Blurb";
import Boxes from "./components/Boxes";
import Responsiveness from "./components/Responsiveness";
import ContactUs from "./components/ContactUs";

function App() {
	Responsiveness();

	useEffect(() => {
		let myimages = [];
		function preloadimages(arr) {
			for (let i = 0; i < arr.length; i++) {
				myimages[i] = new Image();
				myimages[i].src = arr[i];
			}
		}
		preloadimages([
			require("./images/CriminalLaw.jpg"),
			require("./images/TrafficLaw.jpg"),
			require("./images/CivilandCommercalLitigation.jpg"),
			require("./images/SaleandPurchaseofProperty.jpg"),
			require("./images/harbour.jpg"),
			require("./images/pantheon.png"),
			require("./images/library.png"),
		]);
	}, []);

	// Fade-in effects
	useEffect(() => {
		$(document).ready(function () {
			$("div#root").scroll(function () {
				if ($(this).scrollTop() > 0) {
					let ratio = $(this).scrollTop() / $(document).height();
					$(".overlay").css({ opacity: ratio }); //fade image to color
				}
			});
		});
	});

	useEffect(() => {
		$(document).ready(function () {
			const height = $(".parallax-wrapper").css("height");
			$(".regular-wrapper").css({ top: height });
			$(window).resize(function () {
				const height = $(".parallax-wrapper").css("height");
				$(".regular-wrapper").css({ top: height });
			});
		});
	});

	return (
		<React.Fragment>
			<Navbar />
			<Parallax />
			<div className="regular-wrapper">
				<div className="content">
					<Segment id="about" type="odd" title="About Us">
						<Blurb />
					</Segment>
					<Segment id="interactive" type="even">
						<Boxes />
					</Segment>
					<Segment type="odd" id="form" title="Contact Us">
						<ContactUs />
					</Segment>
				</div>
			</div>
		</React.Fragment>
	);
}

export default App;
