import React, { useState, useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import { createMuiTheme, useMediaQuery } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import "../css/Navbar.css";
import $ from "jquery";

export default function Navbar(props) {
	const [links, setLinks] = useState(<React.Fragment />);
	const [image, setImage] = useState(<React.Fragment />);
	const [drawerOpen, setDrawerOpen] = useState(false);

	// Creates the default mui theme
	const theme = createMuiTheme();
	// hook to update matches to either true or false based on screen size.
	// true if the screen is larger or equal to the 'lg' breakpoint. deafault 'lg'=1280px
	const matches = useMediaQuery(theme.breakpoints.down("sm"));

	useEffect(() => {
		$(document).ready(function () {
			$("div#root").scroll(function () {
				if ($(this).scrollTop() > 0) {
					let ratio =
						($(this).scrollTop() / $(document).height()) * 2;
					const imageHeight = parseInt(
						$(".logo-image-navbar").css("height")
					);
					const windowHeight = window.innerHeight;
					if (ratio >= 0.3) {
						$("#navbar").css({
							height: imageHeight + 0.035 * windowHeight,
							transform: "scaleY(1)",
						});
						$(".navbar-content").css({ top: "1.4vh" });
						$(".logo-image-navbar").css({ opacity: "1" });
						$(".btn-main").css({ "background-color": "#aa0c31" });
					} else if (ratio < 0.3) {
						$("#navbar").css({ transform: "scaleY(0)" });
						$(".navbar-content").css({ top: "3.5vh" });
						$(".logo-image-navbar").css({ opacity: "0" });
						$(".btn-main").removeAttr("style");
					}
				}
			});
		});
	});

	// Link scrolling effects
	// useEffect(() => {
	// 	const imageHeight = parseInt($(".logo-image-navbar").css("height"))
	// 	const windowHeight = window.innerHeight
	// 	const navbarHeight = imageHeight+0.03*windowHeight
	// 	$(".links").find("a").click((e) => {
	// 		e.preventDefault();
	// 		let section = e.target.hash;
	// 		$("div#root").animate({
	// 			scrollTop: $("div#root").scrollTop() + $(section).offset().top - navbarHeight - parseInt($(".segment").css("margin-top"))
	// 		}, 'slow');
	// 	});
	// });

	useEffect(() => {
		const toggleDrawerOpen = (event) => {
			setDrawerOpen(!drawerOpen);
		};

		const scrollSmoothly = (e) => {
			e.preventDefault();
			setDrawerOpen(false);
			const imageHeight = parseInt($(".logo-image-navbar").css("height"));
			const windowHeight = window.innerHeight;
			const navbarHeight = imageHeight + 0.03 * windowHeight;
			let section = e.target.hash;
			if (
				!matches &&
				(section === "#areas-of-law" || section === "#our-people")
			) {
				section = "#interactive";
			}
			$("div#root").animate(
				{
					scrollTop:
						$("div#root").scrollTop() +
						$(section).offset().top -
						navbarHeight -
						parseInt($(".segment").css("margin-top")),
				},
				"slow"
			);
		};

		if (matches) {
			setLinks(
				<React.Fragment>
					<MenuIcon id="menu-icon" onClick={toggleDrawerOpen} />
					<Drawer
						PaperProps={{ id: "app-drawer" }}
						anchor="top"
						open={drawerOpen}
						onClose={toggleDrawerOpen}
					>
						<a onClick={scrollSmoothly} href="#about">
							About Us
						</a>
						<a onClick={scrollSmoothly} href="#areas-of-law">
							Areas of Law
						</a>
						<a onClick={scrollSmoothly} href="#our-people">
							Our People
						</a>
						<a onClick={scrollSmoothly} href="#form">
							Contact Us
						</a>
					</Drawer>
				</React.Fragment>
			);
			setImage(
				<div className="logo-image-navbar" style={{ marginTop: "1px" }}>
					ALLIED LAWYERS
				</div>
			);
			$(".logo-image").css({
				"vertical-align": "top",
			});
		} else {
			setLinks(
				<div className="links">
					<button className="btn">
						<a onClick={scrollSmoothly} href="#about">
							About Us
						</a>
					</button>
					<button className="btn">
						<a onClick={scrollSmoothly} href="#areas-of-law">
							Areas of Law
						</a>
					</button>
					<button className="btn">
						<a onClick={scrollSmoothly} href="#our-people">
							Our People
						</a>
					</button>
					<button className="btn btn-main" onClick={scrollToDiv}>
						Contact Us
					</button>
				</div>
			);
			setImage(
				<img
					className="logo-image-navbar"
					src={require("../images/logo_transparent_red.png")}
					alt="ALLIED LAWYERS"
				></img>
			);
		}
		$(".logo-image").removeAttr("style");
	}, [matches, drawerOpen]);

	function scrollToDiv() {
		document.getElementById("form").scrollIntoView({ behavior: "smooth" });
	}

	return (
		<React.Fragment>
			<AppBar id="navbar" position="sticky"></AppBar>
			<div className="navbar-content">
				{image}
				{links}
			</div>
		</React.Fragment>
	);
}
