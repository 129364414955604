import React from 'react'

import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedinIcon from '@material-ui/icons/LinkedIn';
import PrintIcon from '@material-ui/icons/Print';


import "../css/ContactDetails.css"

export default function ContactDetails() {
	return (
		<div className="contact-details-container">
			<div id="contact-details-title">ALLIED LAWYERS</div><br />
			<div className="contact-details-text">Suite 30, Level 2</div>
			<div className="contact-details-text">1-5 Harrow Rd, Auburn NSW 2144</div><br />
			<div className="contact-details-text">
				<PhoneIcon className="icon" style={{marginRight:"5px", fill:"#fff"}}/>
				<a href="tel:+61296438022">(02) 9643 8022</a>
			</div>
			<div className="contact-details-text">
				<PrintIcon className="icon" style={{marginRight:"5px", fill:"#fff"}}/>
				<a href="fax:+61296438033">(02) 9643 8033</a>
			</div>
			<div className="contact-details-text">
				<EmailIcon className="icon" style={{marginRight:"5px", fill:"#fff"}}/>
				<a href="mailto:email@allied-lawyers.com.au">reception@allied-lawyers.com.au</a>
			</div><br />
			<div className="contact-details-social-media">
				<div className="contact-details-social-media-link">
					<a href="https://www.facebook.com/pages/Allied-Lawyers/498865723564623">
						<FacebookIcon className="social-icon" fontSize="large" style={{marginRight:"5px", fill:"#fff"}}/>
					</a>
				</div>
				<div className="contact-details-social-media-link">
					<a href="https://www.linkedin.com/company/allied-lawyers/about/">
						<LinkedinIcon className="social-icon" fontSize="large" style={{marginRight:"5px", fill:"#fff"}}/>
					</a>
				</div>
			</div>
		</div>
	)
}
