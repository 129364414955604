import React, { useState } from 'react'
import {
	Grid, 
	TextField, 
	Button, 
	createMuiTheme, 
	ThemeProvider, 
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup
} from '@material-ui/core'
import "../css/ContactForm.css"

import $ from 'jquery'

const theme = createMuiTheme({
	palette: {
		primary: {
			main: "#fff"
		}
	}
})

export default function ContactForm() {

	const [formDetails, setFormDetails] = useState({
		firstName : "",
		lastName : "",
		contactNumber: "",
		emailAddress: "",
		message: "",
		existingClient: ""
	})

	const postData = async (url, data) => {
		const response = await fetch(url, {
			method : 'POST',
			headers: {
				'Content-Type' : 'Application/JSON'
			},
			body: JSON.stringify(data)
		})
		return response;
	}

	const handleSubmit = (event) => {
		event.preventDefault();

		$(".form-message#success").css({
			"opacity":"0",
			"visibility":"hidden"
		})
		$(".form-message#failure").css({
			"opacity":"0",
			"visibility":"hidden"
		})	
		$("#spinner").css({
			"opacity":"1",
			"visibility":"visible"
		})
		postData("submit", formDetails)
			.then((res) => {
				if (res['status'] === 200) {
					console.log("Message sent successfully!");
					$(".form-message#success").css({
						"opacity":"1",
						"visibility":"visible"
					})
					$("#spinner").css({
						"opacity":"0",
						"visibility":"hidden"
					})
				} else {
					console.log("Message failed to send! :(");
					$(".form-message#failure").css({
						"opacity":"1",
						"visibility":"visible"
					})
					$("#spinner").css({
						"opacity":"0",
						"visibility":"hidden"
					})
				}
			})
		setFormDetails({
			firstName : "",
			lastName : "",
			contactNumber: "",
			emailAddress: "",
			message: "",
			existingClient: ""
		})
	}

	return (
		<ThemeProvider theme={theme}>
			<form method="POST" action="submit" onSubmit={handleSubmit}>
				<Grid container justify="center" spacing={2} style={{width:"100%", margin:"0"}}>
					<Grid item style={{maxWidth:"400px", width:"100%"}}>
						<Grid container spacing={2}> 
							<Grid item xs={12}>
								<TextField 
									label="First Name"
									name="firstName"
									fullWidth
									required
									value={formDetails.firstName}
									onChange={(event) => {
										setFormDetails({
											...formDetails,
											firstName : event.target.value
										})
									}}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField 
									label="Last Name"
									name="lastName"
									fullWidth
									required
									value={formDetails.lastName}
									
									onChange={(event) => {
										setFormDetails({
											...formDetails,
											lastName : event.target.value
										})
									}}
									
								/>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField 
									label="Contact Number"
									name="contactNumber"
									type="number"
									fullWidth
									required
									value={formDetails.contactNumber}
									
									onChange={(event) => {
										setFormDetails({
											...formDetails,
											contactNumber : event.target.value
										})
									}}
									
									/>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField 
									label="Email"
									name="email"
									fullWidth
									required
									type="email"
									value={formDetails.emailAddress}
									
									onChange={(event) => {
										setFormDetails({
											...formDetails,
											emailAddress : event.target.value
										})
									}}
									
									/>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField 
									label="Message"
									name="message"
									multiline
									rows="5"
									fullWidth
									required
									value={formDetails.message}
									
									onChange={(event) => {
										setFormDetails({
											...formDetails,
											message : event.target.value
										})
									}}
									
									/>
							</Grid>
						</Grid>
						<Grid container spacing={2} justify="flex-start" style={{textAlign:"left", paddingBottom:"10px"}}>
							<Grid item xs={12}>
								<FormLabel> Are you an existing client? * </FormLabel>
								<br />
								<RadioGroup 
									style={{flexDirection:"row"}}
									onChange={(event) => {
										setFormDetails({
											...formDetails,
											existingClient : event.target.value
										})
									}}
									value={formDetails.existingClient}
								>
									<FormControlLabel value="Yes" label="Yes" style={{color:"white"}} control={<Radio required color="primary" style={{color:"white"}}/>}/>
									<FormControlLabel value="No" label="No" style={{color:"white"}} control={<Radio required color="primary" style={{color:"white"}}/>}/>
								</RadioGroup>
							</Grid>
						</Grid>
						<Grid container justify="center" spacing={2}>
							<Grid item xs={12} >
								<Button
									type="submit"
									variant="contained"
									fullWidth
									size="large"
									color="primary"
									>
									Submit
								</Button>
							</Grid>
						</Grid>
						<Grid container justify="center" spacing={2}>
							<Grid item xs={12} style={{position:"relative"}} >
								<div className="lds-ellipsis" id="spinner"><div></div><div></div><div></div><div></div></div>
								<div className="form-message" id="failure">
									Unfortunately we are experiencing some technical difficulties. Please try again at a later time.
								</div>
								<div className="form-message" id="success">
									Your message has been submitted successfully. We will get in touch with you as soon as possible.
								</div>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</form>
		</ThemeProvider>
	)
}
