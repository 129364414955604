import React, { useEffect, useState } from "react";
import "../css/InteractiveBoxLinks.css";
import $ from "jquery";

export default function InteractiveBoxLinks(props) {
	const [className, setClassName] = useState('box-link')

	const handleClick = (event) => {
		event.preventDefault();
		//handleMouseEnter()
		props.handleClick(event);
	};

	useEffect(() => {
		if (props.linksVisible) {
			const delay = (props.index * 0.2).toString() + "s";
			$(".box-link#" + props.id).css({
				transition:
					"opacity 0.7s ease " +
					delay +
					", visibility 1s ease " +
					delay,
				opacity: "1",
				visibility: "visible",
			});
		} else {
			$(".box-link#" + props.id).css({
				transition: "opacity 0.2s ease, visibility 0.2s ease",
				opacity: "0",
				visibility: "hidden",
			});
		}
	}, [props]);

	const handleMouseEnter = () => {
		if (props.linksVisible) {
			try {
				const image = require(`../images/${props.title.replace(/ /g, "")}.jpg`);
				$(".interactive-box#areas-of-law").css({
					"background-image": `url(${image})`
				});
				props.setBackgroundImage(image);
			} catch (err) {
				setClassName('box-link no-click')
			}
		}
	};

	const handleMouseLeave = () => {
		if (props.linksVisible) {
			const image = require(`../images/pantheon.png`);
			$(".interactive-box#areas-of-law").css({
				"background-image": `url(${image})`,
			});
			props.setBackgroundImage(image);
		}
	};

	return (
		<React.Fragment>
			<a
				className={className}
				id={props.id}
				onClick={handleClick}
				href="/"
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			>
				{props.title}
			</a>
			<br />
		</React.Fragment>
	);
}
