import React from 'react'
import '../css/Segment.css'

export default function Segment(props) {
	return (
		<div className={"segment " + props.type} id={props.id}>
			<div className="segment-children" id={props.id}>
				{props.children}
			</div>
		</div>
	)
}
