import React, { useRef, useEffect, useState, useLayoutEffect } from "react";

import "../css/InteractiveBox.css";

import $ from "jquery";

import { createMuiTheme, useMediaQuery } from "@material-ui/core";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";

import PortraitGrid from "./PortraitGrid";

export default function PeopleBox(props) {
	//this is a reference to the current component. used to determine if we clicked outside it.
	const ref = useRef(null);
	//this determines whether the box has been expanded or not
	const [expanded, setExpanded] = useState(false);
	//this gives an indication to the children of this component, the portraits, that they should reset now
	const [resetPortraits, setResetPortraits] = useState(false);
	// determines whether we are hovering over the box
	const [mouseOver, setMouseOver] = useState(false);
	// Tells the children that whether should be visible or not
	const [portraitsVisible, setPortraitsVisible] = useState(false);
	// Tells the box whether or not to ignore an outside click. This way it can wait until fully expanded before accepting clicks.
	const [canClose, setCanClose] = useState(false);
	// This indicates that the box is in process of closing
	const [closing, setClosing] = useState(false)
	//tells whehter the closing icon is visible already
	const [iconVisible, setIconVisible] = useState(false);

	// custom hook definition that return the box sizes every time the screen changes size.
	const useBoxSize = () => {
		const [size, setSize] = useState([0, 0]);
		useLayoutEffect(() => {
			//rezie the box to that the height is 75% of the width.
			const resizeBox = () => {
				// only change if the box is not expanded
				if (!expanded) {
					//based on the size of the mui grid item width - 32 px padding
					const width =
						parseInt($(".box-container#" + props.id).css("width")) -
						32;
					const height = width * 0.75;
					$(box).css({
						height: height,
						width: width,
					});
					// set the size variable to be the width and height
					setSize([width, height]);
					// otherwise use the last defined value for the height.
				} else {
					const height = boxSize[1];
					$(box).css({
						height: height,
					});
				}
			};
			window.addEventListener("resize", resizeBox);
			resizeBox();
			return () => window.removeEventListener("resize", resizeBox);
		}, []);
		return size;
	};

	// Creates the default mui theme
	const theme = createMuiTheme();
	// hook to update matches to either true or false based on screen size.
	// true if the screen is larger or equal to the 'lg' breakpoint. deafault 'lg'=1280px
	const matches = useMediaQuery(theme.breakpoints.up("md"));
	// jquery strings to define the current and other boxes.
	const box = ".interactive-box#" + props.id;
	const otherBoxes = ".interactive-box:not(#" + props.id + ")";
	// hook to update the box size while it is not expanded, base on screen size.
	const boxSize = useBoxSize();

	//The following effect is the core functionality of the boxes. It makes them resposive to all events, including window size changes.
	useEffect(() => {
		if (closing) {
			return
		}
		// Function called to fade out the title of the box
		const fadeTitleOut = () => {
			$(".interactive-box-title#" + props.id).css({
				opacity: "0",
				visibility: "hidden",
			});
		};

		// Function called to fade in the title of the box
		const fadeTitleIn = () => {
			$(".interactive-box-title#" + props.id).css({
				opacity: "1",
				visibility: "visible",
			});
		};

		//Slightly zoom in the background when hovering over the box
		const zoomBackground = (size) => {
			$(box).css({
				"background-size": size,
			});
		};

		//Darken/Brigthen the background on hover
		const fadeBackground = (colour) => {
			$(".box-overlay#" + props.id).css({
				"background-color": colour,
			});
		};

		//Set the position of the closing icon. In the other box it does more.
		const positionContent = () => {
			let timeout = matches ? 750 : 250;
			setTimeout(() => {
				//position the closing icon and text
				let iconLeft = "20px";
				$(`#${props.id} .closing-icon`).css({
					opacity: "1",
					visibility: "visible",
					width: "auto",
					left: iconLeft,
				});
				setIconVisible(true);
			}, timeout);
		};

		if (!expanded) {
			if (mouseOver) {
				//fade out title
				fadeTitleOut();
				//zoom in background
				zoomBackground("110% 110%");
				//make background darker
				fadeBackground("rgba(0,0,0,0.8)");

				setPortraitsVisible(true)
			} else {
				//fade in title
				fadeTitleIn();
				//zoom out background
				zoomBackground("100% 100%");
				//make background lighter
				fadeBackground("rgba(0,0,0,0.5)");

				setPortraitsVisible(false)
			}
		} else {
			if (matches) {
				//fade out the other boxes
				$(otherBoxes).css({
					opacity: "0",
					visibility: "hidden",
				});
				//get the segment's current width
				const segmentWidth = $(".segment-children#interactive").css(
					"width"
				);
				//get the padding value in pixels
				const padding = $(".segment-children#interactive").css(
					"padding-right"
				);
				//get current background width size in pixels
				const currentWidth = (boxSize[0] * 1.1).toString() + "px";
				//set appropriate variables to make the box expand to the left
				$(box).css({
					right: padding,
					width: segmentWidth,
					position: "absolute",
					transition:
						"width 1s cubic-bezier(.19,1,.22,1), opacity 1s ease, background-size 0s ease",
					"background-size": currentWidth + " 110%",
					"background-position": "100% 0%",
				});
			} else {
				//On smaller screen, keep other boxes visible
				$(otherBoxes).css({
					opacity: "1",
					visibility: "visible",
				});
				//reset the current box to a normal width and height
				$(box).removeAttr("style");
				$(box).css({
					width: "100%",
					height: boxSize[1],
					"background-size": "110% 110%",
				});
			}
			//position content, either on the left or center
			if (!iconVisible) {
				positionContent();
			}

			//wait until the box is fully expanded to allow it to close.
			// I would like maybe to have a better way to handle this. For now this will do
			setTimeout(() => {
				setCanClose(true);
			}, 1000);
		}
		// The effect is run if any of the following varaibles changes.
		// MouseOver: Checks is the mouse is over the element.
		// props: the variables passed in to the box from the parent. properties.
		// matches: whether the screen matches sizes larger than or equal to lg. see definition above.
		// expanded: whether the box is currently expanded.
		// box: the jquery string defining this box.
		// otherBoxes: the jquery string defining other boxes.
		// box size: the width/height of the but when it is not expanded.
	}, [mouseOver, props, matches, expanded, box, otherBoxes, boxSize, closing, iconVisible]);

	/**
	 * This effect handles an outside click. If the box is expanded then close. Otherwise nothing.
	 */
	useEffect(function () {
		/**
		 * Alert if clicked on outside of element
		 */
		function handleClickOutside(event) {
			if (!canClose || $(event.target).hasClass("interactive-box") || $(event.target).parents().hasClass("interactive-box")) {
				return;
			}
			if (ref.current && !ref.current.contains(event.target)) {
				if (expanded) {
					if (canClose) {
						handleClose();
						setResetPortraits(false);
						setCanClose(false)
						setClosing(true)
					}
				}
			}
		}

		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
	// eslint-disable-next-line
	}, [canClose]);


	/**
	 * This method closes the box, either from clicking the icon or clicking outside it.
	 */
	const handleClose = () => {
		//Hide the closing icon
		$(`#${props.id} .closing-icon`).css({
			opacity: "0",
			visibility: "hidden",
		});

		//Wait 1 second for all the content to disappear first then close the box.
		setTimeout(() => {
			//If on desktop or on wider screens
			if (matches) {
				// Hide the portraits
				setPortraitsVisible(false);
				//Show the title of the current box
				$(".interactive-box-title#" + props.id).css({
					opacity: "1",
					visibility: "visible",
				});
				//Brighten the background color
				$(".box-overlay#" + props.id).css({
					"background-color": "rgba(0,0,0,0.5)",
				});
				//Reset of the box the size to the original size.
				$(box).css({
					width: boxSize[0],
					"background-size":
						boxSize[0].toString() +
						"px " +
						boxSize[1].toString() +
						"px",
					transition:
						"width 1s cubic-bezier(.19,1,.22,1), opacity 1s ease, visibility 1s ease, background-size 1s ease",
				});
				//Show the other boxes after a slight delay
				setTimeout(() => {
					$(otherBoxes).css({ opacity: "1", visibility: "visible" });
				}, 250);
				//Finally after the animations are done, reset the box completely
				setTimeout(() => {
					$(box).removeAttr("style");
					$(box).css({
						width: boxSize[0],
						height: boxSize[1],
					});
					setIconVisible(false)
					setExpanded(false);
					setClosing(false);
				}, 1000);
				//If on smaller screens
			} else {
				//Just need to hide the portraits and the reset the box slightly.
				setPortraitsVisible(false);
				// setMouseOver(false);
				setIconVisible(false);
				setExpanded(false);
				setClosing(false)
			}
		}, 1000);
	};

	return (
		<div
			ref={ref}
			className="interactive-box"
			id={props.id}
			onMouseEnter={() => {
				setMouseOver(true);
			}}
			onMouseLeave={() => {
				setMouseOver(false);
			}}
		>
			<div className="box-overlay" id={props.id}>
				<h1 className="interactive-box-title" id={props.id}>
					{props.title}
				</h1>
				<PortraitGrid
					portraitsVisible={portraitsVisible}
					handleClick={() => {
						setExpanded(true);
						setResetPortraits(true);
						setCanClose(false);
					}}
					expanded={expanded}
					resetPortraits={resetPortraits}
				/>
				<CloseSharpIcon
					className="closing-icon"
					fontSize="large"
					onClick={() => {
						setClosing(true)
						handleClose();
						setResetPortraits(false);
					}}
				/>
			</div>
		</div>
	);
}
