import React from "react";
import "../css/Parallax.css";

export default function Parallax() {
	return (
		<React.Fragment>
			<div className="parallax-wrapper">
				<div className="overlay" />
				<div className="before-pw">
					<div className="image-mask" />
				</div>
				<div className="content-image">
					<img
						src={require("../images/logo_transparent_inverted.png")}
						alt="Allied Lawyers"
						id="main-logo"
					/>
					<div style={{ marginTop: "0", marginBottom: "0" }}>
						ALLIED LAWYERS
					</div>
					<p className="principal">Sam Abbas, Principal</p>
				</div>
				<p className="motto">Experience and Compassion</p>
			</div>
		</React.Fragment>
	);
}
