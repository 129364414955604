import React, { useEffect, useState } from "react";
import $ from "jquery";
import "../css/PortraitGrid.css";
import { createMuiTheme, useMediaQuery } from "@material-ui/core";

export default function PortraitGrid(props) {
	// The name under the portrait
	const [name, setName] = useState("");
	//The summary about them
	const [summary, setSummary] = useState("");
	// Whether to allow clicks on the portraits or not
	const [clickable, setClickable] = useState(false);

	// Creates the default mui theme
	const theme = createMuiTheme();
	// hook to update matches to either true or false based on screen size.
	// true if the screen is larger or equal to the 'md' breakpoint. default 'lg'=960px
	const matches = useMediaQuery(theme.breakpoints.up("md"));

	// This handle a click on either of the portraits.
	const handleImageClick = (event) => {
		//Immidiately abort if clicks are not allowed
		if (!clickable) {
			return;
		}
		//Do not allow clicks for the time being
		setClickable(false);
		//This tells the parent to handle click. basically tells the parent to expand.
		props.handleClick();

		const currId = event.target.id;
		// keep static width of the container instead of percentage
		const currWidth = parseInt($(".portrait-grid-container").css("width"));
		$(".portrait-grid-container").css({
			width: currWidth,
		});
		// apply expanded attributes to the grid container
		$(".portrait-grid-container").addClass(
			"portrait-grid-container-expanded"
		);
		// make the name visible
		$(".portrait-name").css({
			opacity: "1",
			visibility: "visible",
		});
		// hide other portraits
		$(".portrait-container")
			.not("#" + currId)
			.css({
				opacity: "0",
				visibility: "hidden",
				transition:
					"opacity 1s ease, visibility 1s ease, height 1s ease",
			});

		//remove the overlay on the portraits
		$(".portrait-overlay").css({
			"background-color": "rgba(0,0,0,0)",
			cursor: "default",
		});

		//Determine whether to expand the left or right portraits.
		if (currId === "top") {
			console.log("top");
			$(".portrait-top-cell").addClass("portrait-expanded");
			setName("Sam Abbas, Principal");
			setSummary(samSummary);
		}
		if (currId === "left") {
			// $(".portrait-bottom-row").addClass("row-expanded");
			$(".portrait-left-cell").addClass("portrait-expanded");
			setName("Aleksandra Krsteski, Solicitor");
			setSummary(aleksSummary);
		} else if (currId === "right") {
			$(".portrait-right-cell").addClass("portrait-expanded");
			setName("Jonathan Abi Harb, Solicitor");
			setSummary(jonSummary);
		}
		//on small screens, remove the static width and show the summary immediately
		if (!matches) {
			$(".portrait-summary").css({
				opacity: "1",
				visibility: "visible",
			});
			$(".portrait-grid-container").removeAttr("style");
			// on bigger screens, wait until the box expands first.
		} else {
			setTimeout(() => {
				//$(".portrait-grid-container").removeAttr("style");
				$(".portrait-summary").css({
					opacity: "1",
					visibility: "visible",
				});
			}, 1000);
		}
	};

	/**
	 * This effect resets the portraits to the appropriate size.
	 */
	useEffect(() => {
		if (!props.resetPortraits) {
			//Disable clicks as the box is closing.
			setClickable(false);
			// Reset the overlay on the portrait
			$(".portrait-overlay").removeAttr("style");
			// Reset the name
			$(".portrait-name").removeAttr("style");
			// Reset the summary
			$(".portrait-summary").removeAttr("style");
			// Reset the container
			$(".portrait-container").removeAttr("style");

			//Remove the portrait expanded class
			$(".portrait-expanded").removeClass("portrait-expanded");
			// Reset the width of the container.
			const currWidth = parseInt(
				$(".portrait-grid-container").css("width")
			);
			$(".portrait-grid-container").css({
				width: currWidth,
			});

			// After the animations are done, remove the expanded class, and remove the static width.
			setTimeout(() => {
				$(".portrait-grid-container-expanded").removeClass(
					"portrait-grid-container-expanded"
				);
				$(".portrait-grid-container").removeAttr("style");
			}, 2000);
		}
	}, [props.resetPortraits]);

	/**
	 * Effect to show and hide the portraits.
	 */
	useEffect(() => {
		if (props.portraitsVisible) {
			$("#top.portrait-container").css({
				transition: "opacity 0.7s ease 0.2s",
				opacity: "1",
				visibility: "visible",
			});
			$("#left.portrait-container").css({
				transition: "opacity 0.7s ease 0.4s",
				opacity: "1",
				visibility: "visible",
			});
			$("#right.portrait-container").css({
				transition: "opacity 0.7s ease 0.6s",
				opacity: "1",
				visibility: "visible",
			});
			setClickable(true);
		} else {
			$(".portrait-container").css({
				opacity: "0",
				visibility: "hidden",
				transition:
					"opacity 0.5s ease, visibility 0.5s, height 1s ease",
			});
			setClickable(false);
		}
	}, [props.portraitsVisible]);

	return (
		<React.Fragment>
			<div className="portrait-grid-container">
				<div className="portrait-top-row">
					<div className="portrait-top-cell">
						<div className="portrait-container" id="top">
							<img
								className="portrait"
								src={require("../images/samabbas.jpg")}
								alt="portrait"
								style={{ maxHeight: "100%" }}
							/>
							<div
								className="portrait-overlay"
								id="top"
								onClick={handleImageClick}
							/>
						</div>
					</div>
				</div>
				<div className="portrait-bottom-row">
					<div className="portrait-left-cell">
						<div className="portrait-container" id="left">
							<img
								className="portrait"
								src={require("../images/aleks.jpeg")}
								alt="portrait"
								style={{ maxHeight: "100%" }}
							/>
							<div
								className="portrait-overlay"
								id="left"
								onClick={handleImageClick}
							/>
						</div>
					</div>
					<div className="portrait-right-cell">
						<div className="portrait-container" id="right">
							<img
								className="portrait"
								src={require("../images/jonathanabiharb.jpg")}
								alt="portrait"
								style={{ maxHeight: "100%" }}
							/>
							<div
								className="portrait-overlay"
								id="right"
								onClick={handleImageClick}
							/>
						</div>
					</div>
				</div>
				<div className="portrait-name">{name}</div>
			</div>
			<div className="portrait-summary">{summary}</div>
		</React.Fragment>
	);
}

const samSummary = (
	<div>
		<p>
			With over 20 years of experience, Sam has an extensive knowledge of the law
			and has represented hundreds of clients in matters covering all aspects
			of criminal law.    
		</p>
		<p>
			His dynamic personality, coupled with his expertise, has resulted in a proven
			track record that stands as a testament to his success in the profession.
		</p>
	</div>
);

const aleksSummary = (
	<div>
		<p>
			Following the completion of her law degree from University of NSW, Aleksandra
			joined the Allied team and has demonstrated her passion and skill for Criminal 
			Law and advocacy.
		</p>
		<p>
			Her empathy and understanding nutures a relationship of trust with 
			her clients, assisting her in representing them to the fullest degree so as to 
			ensure the best possible outcome. 
		</p>
		
	</div>
);

const jonSummary = (
	<div>
		<p>
			Jonathan has successfully defended clients facing charges across 
			the full spectrum of criminal law, ranging from traffic matters and 
			AVO's to murder and manslaughter. 
		</p>
		<p>
			His excellent results are a testimony to his competence and ability as a lawyer. 
		</p>
	</div>
);
