import React, { useRef, useEffect, useState, useLayoutEffect } from "react";

import "../css/InteractiveBox.css";

import $ from "jquery";

import { createMuiTheme, useMediaQuery } from "@material-ui/core";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";

import InteractiveBoxLinks from "./InteractiveBoxLinks";

export default function AreasBox(props) {
	//Determines whether the box is expanded or closed.
	const [expanded, setExpanded] = useState(false);
	//Determines what content to show what link has been clicked on.
	const [visibleContent, setVisibleContent] = useState(<React.Fragment />);
	//Determines if we are hoveing over the box
	const [mouseOver, setMouseOver] = useState(false);
	//Determines whether we should show the links or hide them
	const [linksVisible, setLinksVisible] = useState(false);
	//Reference to this component to determine if clicked outside.
	const ref = useRef(null);
	// Tells the box whether or not to ignore an outside click. This way it can wait until fully expanded before accepting clicks.
	const [canClose, setCanClose] = useState(false);
	// This indicates that the box is in process of closing
	const [closing, setClosing] = useState(false)
	//tells whehter the closing icon is visible already
	const [iconVisible, setIconVisible] = useState(false);

	//Sets the background image based on the links.
	const [backgroundImage, setBackgroundImage] = useState(
		`url(${require("../images/pantheon.png")}`
	);

	// custom hook definition that return the box sizes every time the screen changes size.
	const useBoxSize = () => {
		const [size, setSize] = useState([0, 0]);
		useLayoutEffect(() => {
			//rezie the box to that the height is 75% of the width.
			const resizeBox = () => {
				// only change if the box is not expanded
				if (!expanded) {
					//based on the size of the mui grid item width - 32 px padding
					const width =
						parseInt($(".box-container#" + props.id).css("width")) -
						32;
					const height = width * 0.75;
					$(box).css({
						height: height,
						width: width,
					});
					// set the size variable to be the width and height
					setSize([width, height]);
					// otherwise use the last defined value for the height.
				} else {
					const height = boxSize[1];
					$(box).css({
						height: height,
					});
				}
			};
			window.addEventListener("resize", resizeBox);
			resizeBox();
			return () => window.removeEventListener("resize", resizeBox);
		}, []);
		return size;
	};

	// Creates the default mui theme
	const theme = createMuiTheme();
	// hook to update matches to either true or false based on screen size.
	// true if the screen is larger or equal to the 'lg' breakpoint. deafault 'lg'=1280px
	const matches = useMediaQuery(theme.breakpoints.up("md"));
	// jquery strings to define the current and other boxes.
	const box = ".interactive-box#" + props.id;
	const otherBoxes = ".interactive-box:not(#" + props.id + ")";
	// hook to update the box size while it is not expanded, base on screen size.
	const boxSize = useBoxSize();

	//The following effect is the core functionality of the boxes. I makes them resposive to all events, including window size changes.
	useEffect(() => {
		if (closing) {
			return
		}
		// Function called to fade out the title of the box
		const fadeTitleOut = () => {
			$(".interactive-box-title#" + props.id).css({
				opacity: "0",
				visibility: "hidden",
			});
		};

		// Function called to fade in the title of the box
		const fadeTitleIn = () => {
			$(".interactive-box-title#" + props.id).css({
				opacity: "1",
				visibility: "visible",
			});
		};

		//Slightly zoom in the background when hovering over the box
		const zoomBackground = (size) => {
			$(box).css({
				"background-size": size,
			});
		};

		//Darken/Brigthen the background on hover
		const fadeBackground = (colour) => {
			$(".box-overlay#" + props.id).css({
				"background-color": colour,
			});
		};

		//Set the position of the title, content and closing icon when the box is open.
		const positionContent = () => {
			let timeout = matches ? 750 : 250;
			setTimeout(() => {
				// On bigger screens the title goes on the left and content to the right
				let width = "40%";
				let left = "57.5%";
				let iconLeft = "55%";
				let titleLeft = "0%";
				let titleWidth = "53.5%";
				let titleTop = "auto";

				//position the closing icon and text on small screens.
				//basically everything is centered and stacked.
				if (!matches) {
					width = "80%";
					left = "10%";
					titleWidth = "100%";
					titleLeft = "0%";
					titleTop = "10%";
					iconLeft = "10px";
				}

				$(".box-content").css({
					opacity: "1",
					visibility: "visible",
					width: width,
					left: left,
				});
				$(".box-content-title").css({
					opacity: "1",
					visibility: "visible",
					width: titleWidth,
					left: titleLeft,
					top: titleTop,
				});
				$(`#${props.id} .closing-icon`).css({
					opacity: "1",
					visibility: "visible",
					width: "auto",
					left: iconLeft,
				});
				setIconVisible(true);
			}, timeout);
		};

		if (!expanded) {
			if (mouseOver) {
				//fade out title
				fadeTitleOut();
				//zoom in background
				zoomBackground("110% 110%");
				//make background darker
				fadeBackground("rgba(0,0,0,0.8)");

				setLinksVisible(true)
			} else {
				//fade in title
				fadeTitleIn();
				//zoom out background
				zoomBackground("100% 100%");
				//make background lighter
				fadeBackground("rgba(0,0,0,0.5)");

				setLinksVisible(false)
			}
		} else {
			if (matches) {
				//fade out the other boxes
				$(otherBoxes).css({
					opacity: "0",
					visibility: "hidden",
				});
				//get the segment's current width
				const segmentWidth = $(".segment-children#interactive").css(
					"width"
				);
				//get current background width size in pixels
				const currentWidth = (boxSize[0] * 1.1).toString() + "px";
				//set appropriate variables to make the box expand to the right
				$(box).css({
					"background-size": currentWidth + " 110%",
					"background-image": backgroundImage,
					width: segmentWidth,
					position: "absolute",
					transition:
						"width 1s cubic-bezier(.19,1,.22,1), opacity 1s ease, visibility 1s ease, background-size 0s ease, background-image 0.5s ease",
					"z-index": "10",
				});
			} else {
				//On smaller screen, keep other boxes visible
				$(otherBoxes).css({
					opacity: "1",
					visibility: "visible",
				});
				//reset the current box to a normal width and height
				$(box).removeAttr("style");
				$(box).css({
					width: "100%",
					height: boxSize[1],
					"background-size": "110% 110%",
					"background-image": backgroundImage,
				});
			}
			//position content, either on the left, right or center
			if(!iconVisible) {
				positionContent();
			}

			//wait until the box is fully expanded to allow it to close.
			// I would like maybe to have a better way to handle this. For now this will do
			setTimeout(() => {
				setCanClose(true);
			}, 1000);
		}
		// The effect is run if any of the following varaibles changes.
		// MouseOver: Checks is the mouse is over the element.
		// props: the variables passed in to the box from the parent. properties.
		// matches: whether the screen matches sizes larger than or equal to lg. see definition above.
		// expanded: whether the box is currently expanded.
		// box: the jquery string defining this box.
		// otherBoxes: the jquery string defining other boxes.
		// box size: the width/height of the but when it is not expanded.
	}, [
		mouseOver,
		props.id,
		matches,
		expanded,
		box,
		otherBoxes,
		boxSize,
		backgroundImage,
		closing,
		iconVisible
	]);

	/**
	 * This is an effect that determines whether the user clicked outside the box.
	 */
	useEffect(function () {
		/**
		 * Alert if clicked on outside of element
		 */
		function handleClickOutside(event) {
			if (!canClose || $(event.target).hasClass("interactive-box") || $(event.target).parents().hasClass("interactive-box")) {
				return;
			}
			if (ref.current && !ref.current.contains(event.target)) {
				if (expanded) {
					if (canClose) {
						handleClose();
						setCanClose(false);
						setClosing(true);
					}
				}
			}
		}

		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
	});

	/**
	 * This method closes the box, either from clicking the icon or clicking outside it.
	 */
	const handleClose = () => {
		// Hide the content
		$(".box-content").css({
			opacity: "0",
			visibility: "hidden",
		});
		// Hide the content title
		$(".box-content-title").css({
			opacity: "0",
			visibility: "hidden",
		});
		//Hide the closing icon
		$(`#${props.id} .closing-icon`).css({
			opacity: "0",
			visibility: "hidden",
		});

		//Everything else should happen after the above takes place. 1 second delay
		setTimeout(() => {
			//On bigger screens
			if (matches) {
				// Show the box title. 'Areas of Law'
				$(".interactive-box-title#" + props.id).css({
					opacity: "1",
					visibility: "visible",
				});
				// brighten the background
				$(".box-overlay#" + props.id).css({
					"background-color": "rgba(0,0,0,0.5)",
				});
				// Reset the size of the box and the background image
				$(box).css({
					width: boxSize[0],
					"background-size":
						boxSize[0].toString() +
						"px " +
						boxSize[1].toString() +
						"px",
					"background-image": `url(${require("../images/pantheon.png")})`,
					transition:
						"width 1s cubic-bezier(.19,1,.22,1), opacity 1s ease, visibility 1s ease, background-size 1s ease, background-image 1s ease",
				});
				//After a slight delay show the other box
				setTimeout(() => {
					$(otherBoxes).css({ opacity: "1", visibility: "visible" });
				}, 250);
				//The after the closing animation completely reset this box
				setTimeout(() => {
					$(box).removeAttr("style");
					$(box).css({
						width: boxSize[0],
						height: boxSize[1],
						transition:
							"width 1s cubic-bezier(.19,1,.22,1), opacity 1s ease, visibility 1s ease, background-size 1s ease, background-image 0.5s ease",
					});
					setIconVisible(false);
					setExpanded(false)
					setClosing(false);
				}, 1000);
				// Set the content to be blank.
				setVisibleContent(<React.Fragment />);
				// On small screens, only change the image and some resetting need to be done
			} else {
				$(box).css({
					"background-image": `url(${require("../images/pantheon.png")})`,
				});
				setLinksVisible(false);
				setIconVisible(false)
				setExpanded(false);
				setClosing(false);
				setVisibleContent(<React.Fragment />);
			}
		}, 1000);
	};

	return (
		<div
			ref={ref}
			className="interactive-box"
			id={props.id}
			onMouseEnter={() => {
				setMouseOver(true);
			}}
			onMouseLeave={() => {
				setMouseOver(false);
			}}
		>
			<div className="box-overlay" id={props.id}>
				<h1 className="interactive-box-title" id={props.id}>
					{props.title}
				</h1>
				<div className="links-container">
					{props.links.map((link, index) => (
						<InteractiveBoxLinks
							title={link['title']}
							key={index}
							id={props.id + index}
							index={index}
							linksVisible={linksVisible}
							handleClick={() => {
								if (link['component']) {
									setExpanded(true);
									setLinksVisible(false);
									console.log(link['component'])
									setVisibleContent(link['component']);
									setCanClose(false);
								}
							}}
							setBackgroundImage={(image) => {
								setBackgroundImage(`url(${image})`);
							}}
						/>
					))}
				</div>
				<CloseSharpIcon
					className="closing-icon"
					id={props.id}
					fontSize="large"
					onClick={() => {
						setClosing(true);
						handleClose();
					}}
				/>
				{visibleContent}
			</div>
		</div>
	);
}
