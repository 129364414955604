import React, {useEffect} from 'react'

import ContactForm from '../components/ContactForm';
import ContactDetails from '../components/ContactDetails';
import {Grid, createMuiTheme, useMediaQuery} from '@material-ui/core'

import $ from 'jquery'

export default function ContactUs() {

    // Creates the default mui theme
	const theme = createMuiTheme()
	// hook to update matches to either true or false based on screen size.
	// true if the screen is larger or equal to the 'md' breakpoint. default 'lg'=1280px
	const matches = useMediaQuery(theme.breakpoints.up('lg'))

    useEffect(() => {
		const resizeMapAndLogo = () => {
            if (matches) {
                const mapWidth = $("#map-cell").css("width");
                const contactCellHeight = $("#contact-cell").css("height");
                const numWidth = parseInt(mapWidth) * 0.8
                $("#map-cell").css({
                    "min-height":numWidth,
                    "height":contactCellHeight
                });
                $("#contact-cell").css({
                    "min-height":numWidth,
                });             
            } else {
                const mapWidth = $("#map-cell").css("width");
                const numWidth = parseInt(mapWidth) * 0.8
                $("#map-cell").css({
                    "min-height":numWidth,
                    "height":mapWidth
                });
            }
            const detailsWidth = $(".contact-details-container").width();
            $("#contact-logo-container").css({
                'width': detailsWidth + 'px'
            })
            $(".contact-us-title-container").css({
                "width" : detailsWidth + 'px'
            })
		}
        window.addEventListener('resize', resizeMapAndLogo);
        setTimeout (() => {
		    resizeMapAndLogo();
        }, 500)
		return () => window.removeEventListener('resize', resizeMapAndLogo);
	})

    return (
        <div>
           <Grid container justify="center" alignItems="center" alignContent="center" style={{height:"fit-content"}}>
                <Grid id="contact-cell" item xs={12} lg={6}>
                    <Grid container>
                        <Grid className="contact-us-title" item xs={12}>
                            <div className="contact-us-title-container">
                                Contact Us
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} md={6} lg={12} xl={6} id="contact-details-cell">
                            <div id="contact-logo-container" style={{textAlign: "left", }}>
                                <img src={require("../images/logo_transparent_red.png")} alt="Allied Lawyers" id="contact-us-logo"/>
                            </div>
                            <ContactDetails />
                        </Grid>
                        <Grid item xs={12} md={6} lg={12} xl={6}>
                            <ContactForm />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid id="map-cell" item xs={12} lg={6} style={{height:"100%", minHeight:"600px"}}>
                    <iframe 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3313.5111377732255!2d151.0291140157637!3d-33.85071578066102!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12bcb083ef5b17%3A0x2e11750d1fd5e974!2sAllied%20Lawyers!5e0!3m2!1sen!2sau!4v1586839481443!5m2!1sen!2sau" 
                        title="map"
                        style={{border:0, width: "100%", height:"100%"}}
                    >
                    </iframe>
                </Grid>
            </Grid> 
        </div>
    )
}
