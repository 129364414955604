import React from "react";

import Grid from "@material-ui/core/Grid";
import PeopleBox from "./PeopleBox";
import AreasBox from "./AreasBox";
import {
	CriminalLaw,
	TrafficLaw,
	Litigation,
	Conveyancing,
} from "./BoxContent";

export default function Boxes() {
	return (
		<React.Fragment>
			<Grid container spacing={4}>
				<Grid
					item
					className="box-container"
					id="areas-of-law"
					xs={12}
					md={6}
				>
					<AreasBox
						id="areas-of-law"
						title="Areas of Law"
						links={[
							{ "title": "Criminal Law", "component": <CriminalLaw />	},
							{ "title": "Traffic Law", "component": <TrafficLaw /> },
							{ "title": "Sale and Purchase of Property", "component": <Conveyancing /> },
							{ "title": "Sale and Purchase of Business", "component": undefined },
							{ "title": "Leasing and Franchises", "component": undefined },
							{ "title": "Civil and Commercal Litigation", "component": <Litigation /> },
							{ "title": "Wills and Estates", "component":undefined },
						]}
					/>
				</Grid>
				<Grid
					item
					className="box-container"
					id="our-people"
					xs={12}
					md={6}
				>
					<PeopleBox
						id="our-people"
						title="Our People"
						// links={["Hello World"]}
					/>
				</Grid>
			</Grid>
		</React.Fragment>
	);
}
